import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import Heatmap from '../components/D3/Heatmap'
import config from '../../data/SiteConfig'

const Betsy = () => (
  <Layout>
    <Helmet title={config.siteTitle} />
    <Heatmap />
  </Layout>
)

export default Betsy